import React from 'react'
import './BabyMobi.scss'
import banner1 from '../../../Asset/Banner left1.png'
import banner2 from '../../../Asset/vertical banner2.png'
import banner3 from '../../../Asset/b3.png'
import banner4 from '../../../Asset/image 55 mobi.png'
import banner5 from '../../../Asset/Banner right5.png'
const BabyMobi = () => {
  return (
    <div className='BabyMobi_container'>
        <div className="BabyMobi_block">
      <img src={banner1} alt="" />
        </div>
        <div className="BabyMobi_block">
      <img src={banner2} alt="" />
        </div>
        <div className="BabyMobi_block">
      <img src={banner3} alt="" />
        </div>
        <div className="BabyMobi_block">
      <img src={banner4} alt="" />
        </div>
        <div className="BabyMobi_block">
      <img src={banner5} alt="" />
        </div>

    </div>
  )
}

export default BabyMobi
