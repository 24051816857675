import img1 from '../../Asset/Brend/1.png'
import img2 from '../../Asset/Brend/2.png'
import img3 from '../../Asset/Brend/3.png'
import img4 from '../../Asset/Brend/4.png'
import img5 from '../../Asset/Brend/5.png'
import img6 from '../../Asset/Brend/6.png'
import img7 from '../../Asset/Brend/7.png'
import img8 from '../../Asset/Brend/9.png'
import img9 from '../../Asset/Brend/10.png'
import img10 from '../../Asset/Brend/11.png'
import img11 from '../../Asset/Brend/12.png'
import img12 from '../../Asset/Brend/13.png'
import img13 from '../../Asset/Brend/14.png'
import img14 from '../../Asset/Brend/15.png'
import img15 from '../../Asset/Brend/16.png'
import img16 from '../../Asset/Brend/17.png'
import img17 from '../../Asset/Brend/18.png'
import img18 from '../../Asset/Brend/19.png'
import img19 from '../../Asset/Brend/20.png'
import img20 from '../../Asset/Brend/21.png'
import img21 from '../../Asset/Brend/22.png'
import img22 from '../../Asset/Brend/Frame 8904.png'
import img23 from '../../Asset/Brend/Frame 8905.png'
import img24 from '../../Asset/Brend/Frame 8906.png'
import img25 from '../../Asset/Brend/Frame 8907.png'
import img26 from '../../Asset/Brend/Frame 8910.png'
import img27 from '../../Asset/Brend/Frame 8911.png'
import img28 from '../../Asset/Brend/Frame 8912.png'
import img29 from '../../Asset/Brend/Frame 8913.png'
import img30 from '../../Asset/Brend/Frame 8914.png'
import img31 from '../../Asset/Brend/Frame 8915.png'
import img32 from '../../Asset/Brend/Frame 8916.png'
import img33 from '../../Asset/Brend/Frame 8917.png'
import img34 from '../../Asset/Brend/Frame 8926.png'
import img35 from '../../Asset/Brend/Frame 8927.png'
import img36 from '../../Asset/Brend/Frame 8928.png'
import img37 from '../../Asset/Brend/Frame 8929.png'
import img38 from '../../Asset/Brend/Frame 8939.png'
import img39 from '../../Asset/Brend/Frame 8940.png'
import img40 from '../../Asset/Brend/Frame 8941.png'
import img41 from '../../Asset/Brend/Frame 8942.png'
import img42 from '../../Asset/Brend/Frame 8943.png'
import img43 from '../../Asset/Brend/Frame 8944.png'
import img44 from '../../Asset/Brend/Frame 8945.png'
import img45 from '../../Asset/Brend/Frame 8946.png'
import img46 from '../../Asset/Brend/Frame 8947.png'
import img47 from '../../Asset/Brend/Frame 8948.png'
import img48 from '../../Asset/Brend/Frame 8949.png'
import img49 from '../../Asset/Brend/Frame 8950.png'
import img50 from '../../Asset/Brend/Frame 8951.png'
import img51 from '../../Asset/Brend/Frame 8952.png'
import img52 from '../../Asset/Brend/Frame 8953.png'
import img53 from '../../Asset/Brend/Frame 8954.png'
import img54 from '../../Asset/Brend/Frame 8955.png'
import img55 from '../../Asset/Brend/Frame 8956.png'
import img56 from '../../Asset/Brend/Frame 8957.png'
import img57 from '../../Asset/Brend/Frame 8958.png'
import img58 from '../../Asset/Brend/Frame 8959.png'
import img59 from '../../Asset/Brend/Frame 8960.png'
import img60 from '../../Asset/Brend/Frame 8961.png'
import img61 from '../../Asset/Brend/Frame 8962.png'
import img62 from '../../Asset/Brend/Frame 8963.png'
import img63 from '../../Asset/Brend/Frame 8964.png'
import img64 from '../../Asset/Brend/Frame 8965.png'
import img65 from '../../Asset/Brend/Frame 8966.png'
import img66 from '../../Asset/Brend/Frame 8967.png'
import img67 from '../../Asset/Brend/Frame 8968.png'
import img68 from '../../Asset/Brend/Frame 8969.png'

export const BrendsProduct = [
    {img: img1},
    {img: img2},
    {img: img3},
    {img: img4},
    {img: img5},
    {img: img6},
    {img: img7},
    {img: img8},
    {img: img9},
    {img: img10},
    {img: img11},
    {img: img12},
    {img: img13},
    {img: img14},
    {img: img15},
    {img: img16},
    {img: img17},
    {img: img18},
    {img: img19},
    {img: img20},
    {img: img21},
    {img: img22},
    {img: img23},
    {img: img24},
    {img: img25},
    {img: img26},
    {img: img27},
    {img: img28},
    {img: img29},
    {img: img30},
    {img: img31},
    {img: img32},
    {img: img33},
    {img: img34},
    {img: img35},
    {img: img36},
    {img: img37},
    {img: img38},
    {img: img39},
    {img: img40},
    {img: img41},
    {img: img42},
    {img: img43},
    {img: img44},
    {img: img45},
    {img: img46},
    {img: img47},
    {img: img48},
    {img: img49},
    {img: img50},
    {img: img51},
    {img: img52},
    {img: img53},
    {img: img54},
    {img: img55},
    {img: img56},
    {img: img57},
    {img: img58},
    {img: img59},
    {img: img60},
    {img: img61},
    {img: img62},
    {img: img63},
    {img: img64},
    {img: img65},
    {img: img66},
    {img: img67},
]