import React from 'react'
import './ProductCard.scss'
import img1 from '../../Asset/Picture1.webp.png'
import img2 from '../../Asset/Picture2.webp.png'
import img3 from '../../Asset/Picture3.webp.png'
import img4 from '../../Asset/Picture4.webp.png'
const ProductCard = () => {
  return (
    <div className='productCard_container'>
       <div className="productCard_block">
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
       </div>
    </div>
  )
}

export default ProductCard
