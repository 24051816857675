import React from 'react'
import { productArr } from './Product'
import './Product.scss'
const Product = () => {
  return (
    <div className='product_container'>
      <div className="product_block">
        <h2>Товары для детей и родителей</h2>
        <div className="product_content">
        <div className="product_item">
            {
            productArr.map((i)=> 
            
            <div className="product_card">
                <img src={i.img} alt="" />
                <h3>{i.text}</h3>
            </div>
            )
            }
            
        
        </div>
        </div>
      </div>
    </div>
  )
}

export default Product
