import React from 'react'
import './Baby.scss'
import banner1 from '../../Asset/vertical banner1.png'
import banner2 from '../../Asset/Banner2 left.png'
import banner3 from '../../Asset/Banner3 right.png'
import banner4 from '../../Asset/Banner4 left (1).png'
import banner5 from '../../Asset/image 55.png'
import BabyMobi from './BabyMobi/BabyMobi'
const Baby = () => {
  return (
    <>
<div class="grid-container">
  <div class="block block-1">
    <img src={banner1} alt="" />
  </div>
  <div class="block block-2">
  <img src={banner2} alt="" />
  </div>
  <div class="block block-3">
  <img src={banner3} alt="" />

  </div>
  <div class="block block-4">
  <img src={banner4} alt="" />

  </div>
  <div class="block block-5">
  <img src={banner5} alt="" />

  </div>
</div>
<BabyMobi/>
    </>

  )
}

export default Baby
