
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './SliderTop.css'
import img1 from '../../Asset/Picture → aHR0cHM6Ly9nby5kZXRtaXIuc3QvaW1hZ2VzL2Jhbm5lcnMvYTk1Yjk0N2M5NTQ0MzNiYzMwYjg2MTExZDQzYTZiYmEwYjIzYzI1MS5qcGVn.webp.png'
import img2 from '../../Asset/ItemBanners.png'

function SliderTop() {
   const img = [
      {img: img1},
      {img: img2},

   ]
   const set = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      // autoplay: true,
      // speed: 2000,
      // autoplaySpeed: 2000,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

  
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
       
          }
        },
        {
          breakpoint: 480,
          set: {
            slidesToShow: 1,
            slidesToScroll: 1,
      
          }
        }
      ]
    };
    return (
      <>
            <Slider {...set}>
            {
               img.map((e)=>
                 <div className="sliderTop_container">
              <div className="sliderTop_block">

                <img src={e.img} alt="" />
                </div>
       </div>
                )
               }
            </Slider>
      </>
      )
  }
  
  export default SliderTop;


  