
import './Footer.scss'
import logo from '../../Asset/Logo (3).png'
import twitter from '../../Asset/Vector 90000.png'
import instagram from '../../Asset/Group 8141.png'
import phone from '../../Asset/Vector (6).png'
import map from '../../Asset/Vector 90001.png'
function Footer() {
    return (
        <div className="footer">

       <div className="footer_container">
        <div className="footer_block">
            <img src={logo} alt="" className='footer_logo' />
            <div className="footerSubscribe_block">
                {/* <div className="footerSubscribe_item">
                    <p>Подпишись на рассылку</p>
                    <div className="footerSubscribeInput_block">
                      <input type="text" />
                      <button>Подписаться</button>
                    </div>
                </div> */}
                <div className="footerSokial_block">
                    <div className="footerSokial_item">
                        <a href="tel:+992980222888 " target='_blank' rel="noreferrer">
                        <img src={twitter} alt="" />
                
                        </a>
                    </div>
                    <div className="footerSokial_item">
                        <a href="https://www.instagram.com/boom.boom.baby.tj?igsh=d2hwN3diZHBwaHpj" target='_blank' rel="noreferrer">
                        <img src={instagram} alt="" />
                
                        </a>
                    </div>
                    <div className="footerSokial_item">
                        <a href="https://m.facebook.com/profile.php?id=100092350407781" target='_blank' rel="noreferrer">
                        <img src={phone} alt="" />
                        </a>
                    </div>
                    <div className="footerSokial_item">
                        <a href="https://maps.app.goo.gl/YGKTy2RuCgRjzmgA9" target='_blank' rel="noreferrer">
                        <img src={map} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
       </div>
        </div>
      )
  }
  
  export default Footer;