import React, { useState } from 'react';
import arrow from '../../Asset/arrow_long_left.png';
import logo from '../../Asset/_2266585289568.png';
import phone from '../../Asset/Vector888.png'
import map from '../../Asset/Vector889.png'
import './Header.css';

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
    };


    const handleScroll = () => {
        const scrollTop = window.pageYOffset;


        if (scrollTop > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };


    window.addEventListener('scroll', handleScroll);

    return (
        <div className="header_container">
            <div className="header_block">
                {isScrolled && (
                    <div className="headerTop_block" onClick={scrollToTop}>
                        <img src={arrow} alt="" />
                        <p className='headerTop_text'>Наверх</p>
                    </div>
                )}
                <img src={logo} alt="" className='header_logo' />
               {
                  !isScrolled && 
                  <div className='headerTop_item'>
                    <div className="headerTopItemIcon_block">
                      <img src={phone} alt="" />                        
                    <h5>+992 980222888</h5> 
                    </div>
                    <div className="headerTopItemIcon_block">
                      <img src={map} alt="" />
                    <h5 className='headerTopItem_text'>ул. Айни 46</h5>
                    </div>
                    </div>
               } 
            </div>
        </div>
    );
}

export default Header;
