
import img1 from '../../Asset/Product/div._3_5.png'
import img2 from '../../Asset/Product/div._3_5 (1).png'
import img3 from '../../Asset/Product/div._3_5 (2).png'
import img4 from '../../Asset/Product/div._3_5 (3).png'
import img5 from '../../Asset/Product/image-removebg-preview (2) 1.png'
import img6 from '../../Asset/Product/div._3_5 (5).png'
import img7 from '../../Asset/Product/div._3_5 (6).png'
import img8 from '../../Asset/Product/div._3_5 (7).png'
import img9 from '../../Asset/Product/div._3_5 (8).png'
import img10 from '../../Asset/Product/div._3_5 (9).png'
import img11 from '../../Asset/Product/image-removebg-preview (3) 1.png'
import img12 from '../../Asset/Product/div._3_5 (11).png'
import img13 from '../../Asset/Product/div._3_5 (11).png'
import img14 from '../../Asset/Product/div._3_5 (12).png'
import img15 from '../../Asset/Product/div._3_5 (13).png'
import img16 from '../../Asset/Product/div._3_5 (14).png'
import img17 from '../../Asset/Product/image 5.png'
import img18 from '../../Asset/Product/ddddd.png'
export  const productArr = [
    {img: img1, text: 'Прогулочные коляски'},
    // {img: img2, text: 'Электромобили'},
    {img: img3, text: 'Детские конструкторы'},
    {img: img4, text: 'Хобби и творчество'},
    {img: img5, text: 'Спортивная одежда'},
    {img: img6, text: 'Беговелы'},
    {img: img7, text: 'Коляски для кукол'},
    {img: img8, text: 'Пюре'},
    // {img: img9, text: 'Автокресла'},
    {img: img10, text: 'Стульчики для кормления'},
    {img: img11, text: 'Комбинезоны'},
    // {img: img12, text: 'Futurino home'},
    // {img: img13, text: 'Бытовая техника и электроника'},
    {img: img15, text: 'Настольные игры'},
    {img: img16, text: 'Подгузники-трусики'},
    {img: img18, text: 'Самокаты'},
    {img: img17, text: 'Куртки'},
    // {img: img18, text: 'Детские кроватки'},
]