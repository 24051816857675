import React from 'react'
import './BrendSlider.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from '../../Asset/Picture →1.webp.png'
import img2 from '../../Asset/Picture →2.webp.png'
import img3 from '../../Asset/Picture →3.webp.png'
import img4 from '../../Asset/Picture → 4.webp.png'
import img5 from '../../Asset/Лего 72.png'
import img6 from '../../Asset/Лего - 79.png'
import img7 from '../../Asset/Хаггис.png'
import img8 from '../../Asset/Бюбхен - 50.png'
import img9 from '../../Asset/Бюбхен 46,8.png'
import img10 from '../../Asset/Стол.png'
const BrendSlider = () => {
    const slider = [
        // {img: img1},
        // {img: img2},
        // {img: img3},
        // {img: img4},
        {img: img5},
        {img: img6},
        {img: img7},
        {img: img8},
        {img: img9},
        {img: img10},
    ]
    const seti = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        // autoplay: true,
        // speed: 2000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
  
    
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
         
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
        
            }
          }
        ]
      };
  return (
    <div className='BrendSlider_container'>
      <div className="BrendSlider_block">
        <h2>Предложения от брендов</h2>
        <div className="BrendSlider_item">
            <Slider {...seti}>

            {
            slider.map((i)=>
            <img src={i.img} alt="" />
            )
            }
            </Slider>
           
        </div>
      </div>
    </div>
  )
}

export default BrendSlider
