import React, { useState } from 'react';
import { BrendsProduct } from './Brends';  
import './Brends.scss';

const Brends = () => {
  const [showAll, setShowAll] = useState(false); 

  const showAllBrends = () => {
    setShowAll(!showAll);  
  };
 
  const bendsList = showAll ? BrendsProduct : BrendsProduct.slice(0, 21);

  return (
    <div className="brends">
      <div className='brends_container'>
        <div className="brends_title">
          <h1>Бренды</h1> 
          <button onClick={showAllBrends}>Все бренды</button>
        </div>
        <div className="brends_content">
        <div className="brends_block">
          {
            bendsList.map((i) =>
              <div className='brends_item' key={i.id}>  
                <img src={i.img} alt="" />
              </div>
            )
          }
        </div>
        </div>
      </div>
    </div>
  );
};

export default Brends;
