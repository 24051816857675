import Baby from "../../Components/Baby/Baby";
import BrendSlider from "../../Components/BrendSlider/BrendSlider";
import Brends from "../../Components/Brends/Brends.jsx";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Product from "../../Components/Product/Product.jsx";
import ProductCard from "../../Components/ProductCard/ProductCard.jsx";
import SliderTop from "../../Components/SliderTop/SliderTop";

function Home() {
    return (
        <>
        <Header/>
        <Menu/>
        <SliderTop/>
        <Baby/>
        <BrendSlider/>
        <Product/>
        <Brends/>
        <ProductCard/>
        <Footer/>
        </>
      )
  }
  
  export default  Home;