import img from '../../Asset/IMAGE.png'
import './Menu.css'
function Menu() {
    return (
       <div className="menu_container">
        <div className="menu_block">
            <div className="menu_item">
                <img src={img} alt="" />
                <p>Акции</p> 
            </div>
            <div className="menu_item"> 
                <p>Игрушки и игры</p> 
            </div>
            <div className="menu_item"> 
                <p>Гигиена и уход</p> 
            </div>
            <div className="menu_item"> 
                <p>Детская комната</p> 
            </div>
            <div className="menu_item"> 
                <p>Питание и кормление</p> 
            </div>
            <div className="menu_item"> 
                <p>Одежда и обувь</p> 
            </div>
        </div>
       </div>
      )
  }
  
  export default Menu;